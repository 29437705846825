// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import master from './master'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  master
})

export default rootReducer
