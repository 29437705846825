// ** Logo
// import logo from '@src/assets/images/logo/logo.png'
import sublogo from '@src/assets/images/sublog.png'
import Spinner from 'react-bootstrap/Spinner'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner spinnercompo'>
      <img className='fallback-logo' src={sublogo} alt='logo' />
      {/* <div className='d-flex  h-100'>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="primary" />
      </div> */}
      <div className='loading mt-50 d-flex w-100'>
        <Spinner className='mr-50' size="sm" animation="grow" variant="primary" />
        <Spinner className='mr-50' size="sm" animation="grow" variant="primary" />
        <Spinner size="sm" animation="grow" variant="primary" />

        {/* <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div> */}
      </div>
    </div>
  )
}

export default SpinnerComponent
