// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'
import { useDispatch } from 'react-redux'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  fr: { ...messagesFr, ...userMessagesFr },
  pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('de')
  const [messages, setMessages] = useState(menuMessages['de'])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
    localStorage.setItem("lang", lang)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: "SET_LANG_DATA", payload: messages })
  }, [messages])

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      if (localStorage.getItem("lang") === "en") {
        setMessages(menuMessages['en'])
        setLocale("en")
      } else if (localStorage.getItem("lang") === "fr") {
        setMessages(menuMessages['fr'])
        setLocale("fr")
      } else if (localStorage.getItem("lang") === "de") {
        setMessages(menuMessages['de'])
        setLocale("de")
      }
    }
  }, [])

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
